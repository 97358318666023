<template>
    <div class="gallery" @click="$emit('close'); $refs.player.pause()">
        <button @click="$emit('close'); $refs.player.pause()">
            <svg width="16" height="15" viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
              <path d="m14.718.075.707.707L8.707 7.5l6.718 6.718-.707.707L8 8.207l-6.718 6.718-.707-.707L7.293 7.5.575.782l.707-.707L8 6.793 14.718.075Z" fill="#181818" fill-rule="evenodd"/>
            </svg>
        </button>
         <vueVimeoPlayer        
            v-if="videoMode && videoId !== 0"                            
            frameborder="0"
            class='video'
            :options="{
                responsive:true,
                autoplay:true,
            }"   
            :style="{
                maxWidth:'100vw',
                maxHeight:'100vh'
            }"
            ref="player"
            :video-url='videoId'
        />
        <vueper-slides fade class='no-shadow' @click.stop v-else>
            <vueper-slide
                v-for="(slide, i) in slides"
                :key="i"
                :image="slide.file"
            />
        </vueper-slides>
    </div>
</template>

<script>
 import { vueVimeoPlayer } from 'vue-vimeo-player'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
    name:'Gallery',
    props: {
        images:Array,
        videoMode : Boolean,
        show:Boolean,
        videoId:String
    },
    watch: {
        show() {
            if(this.show) {
                this.$nextTick(() => {
                    //this.$refs.player.play()
                });
            }
        }
    },
    components: { VueperSlides, VueperSlide, vueVimeoPlayer },        
    computed: {        
        slides() {
            let images = [];        
            //some pages put images from the gallery on screen; for instance the UpToThreeImagesLeft template has up to 3 images visible on the left; 
            //so if the rep does add 3 images; two of which would of been in the gallery in the CMS; then they need to be removed from the gallery
            for (let index = 0; index < this.images?.length; index++) {
                const element = this.images[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    .fade-enter-active .vueperslides {
        transition: all 0.3s 0s ease-out;
    }

    .fade-leave-active .vueperslides {
        transition: all 0.3s;
    }

    .fade-enter-from .vueperslides ,
    .fade-leave-to .vueperslides {
        transform: scale(0.9);
        opacity: 0;
    }
    button {
        border-radius: 10px;
        background: linear-gradient(270deg, #DADADA 0%, #FFFFFF 100%);
        display:grid;
        place-items: center;
        position: absolute;
        top:50px;
        right:50px;
        width:50px;
        height:50px;
        border:none;
        outline:none;
        cursor: pointer;
        z-index: 1;
    }
    .video:deep() iframe {
        width:calc(100% - 50px);
        max-height:calc(100vh - 50px);
    }
    .video {        
        position: absolute;
        top:0;
        left:0;
        right:0;
        height:calc(100vh - 50px);
        margin:25px;        
        display:grid;
        place-items: center;
        :deep() > div {
            width: 100%;
        }
        
    }
    .gallery {
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(#000, 0.95);
        
        padding:13px;
        box-sizing: border-box;
        display: grid;
        align-items: center;
                
    }
    .vueperslide :deep(){
        background-repeat: no-repeat;
        background-size: contain;        
        max-height: calc(100vh - 26px);
    }
</style>