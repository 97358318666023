<template>
    <router-link :to="{name:to, params:$route.params}" v-if="to !== ''" :class="[previous ? 'previous' : '']" class='linear'>
        <div>
            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path d="m7 14 7-7-7-7v4.083H0v5.834h7z" fill="#4D4D4D" fill-rule="nonzero"/>
            </svg>
        </div>
    </router-link>     
</template>

<script>
export default {
    name:'LinearButton',
    props : {
        previous:Boolean,
        to:{
            default:'',
            type:String
        }
    },
}
</script>

<style lang="scss" scoped>
    a {
        height: 70px;
        width: 70px;
        border-radius: 10px;
        background-color: rgba(255,255,255,0.1);        
        display: grid;
        place-items: center;
        bottom:16px;
        position: absolute;
        &.previous {
            left:16px;
        }
        &:not(.previous) {
            right:16px;
        }
    }
    div {
        height: 50px;
        width: 50px;
        border-radius: 5px;
        background: linear-gradient(180deg, #F5F5F5 0%, #BCBCBC 100%);
        display: grid;
        place-items: center;
    }
    .previous svg {
        transform:scaleX(-1);
    }
    @media print {
        a {
            display: none;
        }
    }
    @media (min-width:700px) {
        .linear {
            position:fixed;            
        }
    }
</style>