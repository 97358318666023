<template>
  <a v-if="type === 'file'" :href="href" download :class="[type, flipped ? 'flipped' : '']" class='button'>
      <span class='icon'><img :src="getIcon()" alt=""></span>
      <span>{{getText()}} {{title}}</span>
  </a>
  <button v-else :class="[type, flipped ? 'flipped' : '']" class='button'>
      <span class='icon' v-if="type !== 'audio'"><img :src="getIcon()" alt=""></span>
      <span>{{getText()}} {{title}}</span>
  </button>
</template>

<script>
export default {
    name:'AttachmentButton',
    props: {
        flipped:Boolean,
        title :{
            type:String,
            default:''
        },
        type: {
            type:String,
            validator: function (value) {
                return ['video', 'gallery', 'file', 'audio'].indexOf(value) !== -1
            }
        }
    },
    methods : {
        getText() {
            const text = {
                video:"Watch:",
                gallery:"Gallery",
                file:"Download:",
                audio:"Listen:"
            }
            return text[this.type];
        },
        getIcon() {
            const icons = {
                video:"icon_play-video",
                gallery:"icon_gallery",
                file:"icon_download-file",
                audio:"icon_play-video"
            }
            return require(`@/assets/icons/${icons[this.type]}.svg`);
        }
    }
}
</script>

<style lang="scss" scoped>
    a, .button {
        font-family: 'Helvetica Neue LT Std';
        font-weight:bold;
    }
    .button {        
        background:none;
        outline:none;
        border: none;
        display: flex;        
        align-items: center;
        gap:15px;        
        color: #F5F5F5;
        font-size: 0.9375rem;
        letter-spacing: 0;
        line-height: 17px;
        padding:0;
        text-align: left;
        user-select: none;
        &:not(.audio) {
            cursor: pointer;
        }
        &.flipped {
            flex-direction: row-reverse;
        }
    }
    .icon {
        display:grid;
        place-items: center;
        width:31px;
        height:31px;        
        border-radius: 5px;
        flex-shrink: 0;
        position: relative;
        img {
            position: relative;
        }
    }
    .file .icon {
        &:before {
            background-color: #969696;
        }
        border: 1px solid #F5F5F5;
    }    
    .video .icon {
        &:before {
            background-color: #0066CC;
        }
        border: 1px solid #0066CC;
    }
    .audio {
       margin-bottom:8px;
    }
    .gallery .icon {
        &:before {
            background-color: #BD2531;
        }
        border: 1px solid #BD2531;
    }    
    @media (hover: hover) {
      .button {
          .icon:before {
            content:"";
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            transition: 0.2s opacity;
            opacity: 0;            
        }
        &:hover {
            .icon:before {
            opacity: 1;
            }
        }
      }      
    }   
</style>