<script>
    import axios from 'axios';
    import Editor from '@tinymce/tinymce-vue'
    export default {
        name:'Page',
        props: {
            page:Object
        },
        components : {
            Editor
        },
        data() {
            return {
                editorFocused:false,
                videoId:"",
            }
        },
        methods : {
            showVideo(id) {
                this.videoId = id;
                this.videoVisible = true;
            },
            activateEditor() {                
                if(this.$store.state.showBuildMode) {
                    this.editorFocused = true;
                }
            },
            updateContent(e) {
                this.$store.state.overrideCopy = this.copy;
            }
        },
        created() {
            /*
                console.log('created', this.$store.state.purl, this.$store.state.preview, {
                    "section": `c${this.page.id}`,
                    "sessionId": this.$store.state.sessionDbId,
                    "presentationId": this.$store.state.presentationId
                })
            */
            this.$store.state.overrideTemplate = undefined;
            this.$store.state.overrideCopy = undefined;
            this.$store.state.currentPage = this.page
            //if(this.page.previous === undefined){ //this would usually be sufficient but SMI has a strange page that is separate from the rest of the presentation
            if(this.page.previous === undefined && this.$route.name !== this.$store.state.showMeetTheTeam){
                this.$store.state.currentPage.previous = 'Main Navigation';
            }
            
            if(this.$store.state.purl !== 'admin.0000' && this.$store.state.preview === false) {
                axios.post(this.$store.state.baseUrl + "0/submitHit.php", {
                    "section": `c${this.page.id}`,
                    "sessionId": this.$store.state.sessionDbId,
                    "presentationId": this.$store.state.presentationId
                });
            }
        },
        watch : {            
            '$store.state.showBuildMode' (to) {
                if(to === false && this.editorFocused) {
                    this.editorFocused = false;
                }        
            }
        },
        computed : {
            videos() {
                //let videos = [];
                //if(this.page?.video) {
                    //videos.push(this.page.video);            
                //}
                return this.page.videos;
            },
        }
    }
</script>
<style lang="scss">
.tox-tinymce--disabled {
    display: none !important;
}
</style>