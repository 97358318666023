<template>
    <div class="ui" :class="(!page.video && gallery?.length === 0 && page.files?.length === 0 && page.audio?.length === 0) ? 'no-ui' : ''">        
        <div v-for="(audio) in page.audio">
            <AttachmentButton :flipped="flipped" type="audio" :title="audio.title" :key="audio.title" @click="listen"/>
            <audio :src="audio.file" controls/>
        </div> 
        <div v-for="(video) in page.videos">
            <AttachmentButton :flipped="flipped" type="video" :title="video.title" @click="$emit('showVideo', video.embed)"/>                  
        </div>         
        <AttachmentButton :flipped="flipped" v-if="gallery?.length > 0" type="gallery" @click="$emit('showGallery')"/>                          
        <AttachmentButton :flipped="flipped" v-for="(file) in page.files" type="file" :title="file.title" :key="file.title" />                  
    </div>  
</template>

<script>
//remove Get Started link on Title Pages
//<router-link v-if="template == 'titlepage' && page.next" :to="{name:page.next, params:$route.params}">Get Started</router-link>    
import AttachmentButton from '@/components/AttachmentButton';
export default {
    name:'AttachmentUI',
    props : {
        page:Object,
        flipped: {
            type:Boolean,
            default:false,
        },
        template:{
            type:String,
            default:''
        },
        gallery:Array        
    },
    components:{
        AttachmentButton
    }, 
    methods : {
        listen() {

        }
    }   
}
</script>

<style lang="scss" scoped>
    .ui {
        margin-top:30px;
        display:flex;
        align-items: center;
        flex-wrap: wrap;
        gap:24px;
    }  
    .no-ui {
        display:none;
    }  
    a {
        font-family: 'Helvetica Neue LT Std';
        font-weight:bold;
        border-radius: 10px;
        background: linear-gradient(270deg, #DADADA 0%, #FFFFFF 100%);
        text-decoration: none;
        font-size: 0.9375rem;
        letter-spacing: 0;
        line-height: 1;
        color: #181818;
        text-align: center;
        padding:16px 30px;
        display: inline-block;
        flex-shrink: 0;
    }
    @media print {
        .ui {
            display: none;
        }
    }
</style>